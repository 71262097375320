<template>
  <b-container
    fluid
    class="p-0 mb-3"
  >
    <b-spinner
      v-if="dashboardInfo == null"
    />
    <div v-else-if="validSubscription">
      <h1 class="text-black mb-2 font-weight-bolder">
        {{ $t('dashboard.welcomeText', [dashboardInfo.alias]) }}
      </h1>
      <b-row>
        <b-col
          lg="8"
        >
          <b-card
            v-if="showPairingCard"
            :title="$t('dashboard.validSubscriptionUpcomingSessions')"
          >
            <b-card-body class="p-2">
              <b-row>
                <b-col
                  class="d-flex align-items-center justify-content-center border-right-md text-center text-lg-left"
                  md="4"
                >
                  <h3>
                    {{ $t('dashboard.validSubscriptionCalloutPre') }} <span class="text-yellow">{{ $t('dashboard.validSubscriptionCalloutMid') }}</span>{{ $t('dashboard.validSubscriptionCalloutPost') }}
                  </h3>
                </b-col>
                <b-col
                  class="pl-lg-5 d-inline-grid align-content-center text-center text-lg-left"
                  md="8"
                >
                  <h3>
                    {{ $t('dashboard.validSubscriptionDetailTitle') }}
                  </h3>
                  <h5>
                    {{ $t('dashboard.validSubscriptionDetailSubtitle') }}
                  </h5>
                  <b-row>
                    <b-col>
                      <b-button
                        v-if="showCounselor"
                        class="mt-2 w-fit-content"
                        variant="primary"
                        @click="pairWithCounselor"
                      >
                        {{ $t('dashboard.validSubscriptionPairWithCounselor') }}
                      </b-button>
                    </b-col>
                    <b-col>
                      <b-button
                        v-if="showTherapist"
                        class="mt-2 w-fit-content"
                        variant="info"
                        @click="pairWithTherapist"
                      >
                        {{ $t('dashboard.validSubscriptionPairWithTherapist') }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <b-card
            v-if="showCounselorCard"
            :title="$t('dashboard.validSubscriptionUpcomingSessions')"
            no-body
          >
            <b-card-body class="pt-0 pl-0 pr-0 pb-sm-2 pb-md-0">
              <div class="top-banner bg-blue  justify-content-between">
                <div class="d-flex align-items-center">
                  <div>
                    <h4> {{ $t('dashboard.validSubscriptionUpcomingSessions') }}</h4>
                  </div>
                </div>
              </div>
              <b-row class="pt-2 pl-2 pr-2 pr-md-0">
                <b-col
                  class="text-center text-lg-left mb-1"
                  md="3"
                  sm="4"
                >
                  <b-row>
                    <div>
                      <m-avatar
                        :url="counselor.profilePic"
                        :fallback-text="counselor.alias"
                        class="mb-1"
                        style="height: 70px;"
                      />
                    </div>
                    <b-col col="auto">
                      <h5>{{ counselor.alias }}</h5>
                      <h4>{{ $t('dashboard.validSubscriptionHasCounselorMeet') }}</h4>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <p>{{ counselor.bio }}</p>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  class="pl-lg-5 d-sm-inline-grid d-block align-content-center text-center text-sm-left"
                  md="7"
                  sm="8"
                >
                  <h3>
                    {{ $t('dashboard.validSubscriptionCalloutTitle') }}
                  </h3>
                  <h5>
                    {{ $t('dashboard.validSubscriptionCalloutSubtitle') }}
                  </h5>
                  <div class="d-inline">
                    <b-button
                      class="mt-2 mr-1 w-sm-fit-content"
                      variant="outline-primary"
                      @click="pairWithCounselor"
                    >
                      {{ $t('dashboard.changeCounselor') }}
                    </b-button>
                    <b-button
                      class="mt-2 w-sm-fit-content"
                      variant="primary"
                      @click="schedule"
                    >
                      {{ $t('dashboard.schedule') }}
                    </b-button>
                  </div>
                </b-col>
                <b-col
                  md="2"
                  class="d-none d-md-flex align-items-end"
                >
                  <b-img
                    class="w-100"
                    src="@/assets/images/illustrations/smiling_calendar.svg"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <b-card
            v-if="showAddCounselor"
            :title="$t('dashboard.superCharge')"
            style="background: rgb(243, 244, 245);"
          >
            <h3
              style="color:#EF6D3E;"
            >
              {{ $t('dashboard.additionalSupport') }}
            </h3>
            <h5>{{ $t('dashboard.additionalSupportCounselor') }}</h5>
            <b-button
              v-if="showCounselor"
              class="mt-2 w-fit-content"
              variant="primary"
              @click="pairWithCounselor"
            >
              {{ $t('dashboard.validSubscriptionPairWithCounselor') }}
            </b-button>
          </b-card>
          <b-card
            v-if="showTherapistCard"
            :title="$t('dashboard.validSubscriptionUpcomingSessions')"
            no-body
          >
            <b-card-body class="pt-0 pl-0 pr-0 pb-sm-2 pb-md-0 ">
              <div class="top-banner bg-orange  justify-content-between">
                <div class="d-flex align-items-center">
                  <div>
                    <h4> {{ $t('dashboard.validSubscriptionUpcomingSessions') }}</h4>
                  </div>
                </div>
              </div>
              <b-row class="pt-2 pl-2 pr-2 pr-md-0">
                <b-col
                  class="text-center text-lg-left mb-1"
                  md="3"
                  sm="4"
                >
                  <b-row>
                    <div>
                      <m-avatar
                        :url="therapist.profilePic"
                        :fallback-text="therapist.alias"
                        class="mb-1"
                        style="height: 70px;"
                      />
                    </div>
                    <b-col col="auto">
                      <h5>{{ therapist.alias }}</h5>
                      <h4>{{ $t('dashboard.validSubscriptionHasTherapistMeet') }}</h4>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <p>{{ therapist.bio }}</p>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  class="pl-lg-5 d-sm-inline-grid d-block align-content-center text-center text-sm-left"
                  md="7"
                  sm="8"
                >
                  <h3>
                    {{ $t('dashboard.validSubscriptionCalloutTitle') }}
                  </h3>
                  <h5>
                    {{ $t('dashboard.validSubscriptionCalloutSubtitle') }}
                  </h5>
                  <div class="d-inline">
                    <b-button
                      class="mt-2 mr-1 w-sm-fit-content"
                      variant="outline-primary"
                      @click="pairWithTherapist"
                    >
                      {{ $t('dashboard.changeTherapist') }}
                    </b-button>
                    <b-button
                      class="mt-2 w-sm-fit-content"
                      variant="info"
                      @click="scheduleTherapist"
                    >
                      {{ $t('dashboard.schedule') }}
                    </b-button>
                  </div>
                </b-col>
                <b-col
                  md="2"
                  class="d-none d-md-flex align-items-end"
                >
                  <b-img
                    class="w-100"
                    src="@/assets/images/illustrations/smiling_calendar.svg"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <b-card
            v-if="showAddTherapist"
            :title="$t('dashboard.superCharge')"
            style="background: rgb(243, 244, 245);"
          >
            <h3
              style="color:#EF6D3E;"
            >
              {{ $t('dashboard.additionalSupport') }}
            </h3>
            <h5>{{ $t('dashboard.additionalSupportTherapist') }}</h5>
            <b-button
              v-if="showTherapist"
              class="mt-2 w-fit-content"
              variant="info"
              @click="pairWithTherapist"
            >
              {{ $t('dashboard.validSubscriptionPairWithTherapist') }}
            </b-button>
          </b-card>
          <b-row>
            <b-col
              md="6"
            >
              <router-link to="virtual-groups">
                <b-card
                  :title="$t('dashboard.virtualGroupsTitle')"
                  style="min-height:256px;"
                >
                  <p class="text-body">
                    {{ $t('dashboard.virtualGroupsDetail') }}
                  </p>

                  <h5
                    class="mt-1"
                  >
                    {{ $t('generic.letsGo') }}
                    <feather-icon
                      size="17"
                      icon="ArrowRightIcon"
                    />
                  </h5>
                </b-card>
              </router-link>
            </b-col>
            <b-col
              md="6"
            >
              <b-card
                :title="$t('dashboard.tipiTitle')"
                :style="hasCompletedTipi ? {} : { cursor: 'pointer' }"
                style="background: #fcf2d2;min-height: 256px;"
                @click="takeTipi"
              >
                <h5
                  class="font-weight-normal rounded p-2"
                  :style="{ background: 'rgba(255, 255, 255, 0.3)' }"
                >
                  {{ $t(hasCompletedTipi ? 'dashboard.tipiCompleteDetail' : 'dashboard.tipiIncompleteDetail') }}
                  <br><br>
                  {{ $t(hasCompletedTipi ? 'dashboard.tipiCompleteInfo' : 'dashboard.tipiIncompleteInfo') }}
                </h5>

                <h5
                  v-if="!hasCompletedTipi"
                  class="mt-1"
                >
                  {{ $t('generic.letsGo') }}
                  <feather-icon
                    size="17"
                    icon="ArrowRightIcon"
                  />
                </h5>
              </b-card>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          lg="4"
        >
          <card-spinner
            v-if="userSubscription == null"
          />
          <subscription
            v-else-if="userSubscription != null"
            :subscription="userSubscription"
          />
          <quote-card
            :quote="quote"
            :quote-author="quoteAuthor"
          />
        </b-col>
      </b-row>
    </div>
    <div v-else-if="!validSubscription">
      <h1 class="text-black mb-2 font-weight-bolder">
        {{ $t('dashboard.welcomeText', [dashboardInfo.alias]) }}
      </h1>
      <b-card class="p-2">
        <b-row>
          <b-col
            lg="4"
            class="p-2 d-flex align-items-center justify-content-center border-right-lg text-center text-lg-left"
          >
            <h2>
              {{ $t('dashboard.invalidSubscriptionCalloutPre') }} <span class="text-yellow"><b>{{ $t('dashboard.invalidSubscriptionCalloutMid') }}</b></span>{{ $t('dashboard.invalidSubscriptionCalloutPost') }}
            </h2>
          </b-col>
          <b-col
            lg="4"
            class="pl-lg-5 d-inline-grid align-content-center text-center text-lg-left"
          >
            <h3>
              {{ $t('dashboard.invalidSubscriptionDetailTitle') }}
            </h3>
            <h5>
              {{ $t('dashboard.invalidSubscriptionDetailSubtitle') }}
            </h5>
          </b-col>
          <b-col
            lg="4"
            class="d-flex align-items-center justify-content-center"
          >
            <b-g-image-button
              :title="$t('dashboard.subscribeButton')"
              :bg-image="require('@/assets/images/backgrounds/stroke.svg')"
              @click="getStarted"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>

    <div
      v-if="validSubscription"
      style="background-color: #F3F4F5;"
      class="p-4 mb-4"
    >
      <h2>{{ $t('dashboard.footer.needToChat') }}</h2>
      <h6>
        {{ $t('dashboard.footer.weAreHereToHelp') }}
        &nbsp;
        <b-button
          variant="outline-primary"
          size="sm"
          @click="openChat"
        >
          {{ $t('dashboard.footer.weAreHereToHelpAction') }}
        </b-button>
      </h6>
      <br>
      <h6>
        {{ $t('dashboard.footer.researchLanguage') }}
      </h6>
    </div>

    <chat v-if="validSubscription" />
    <chat-unsubscribed v-if="!validSubscription" />
  </b-container>
</template>

<script>
import {
  BCard, BCardTitle, BButton, BRow, BCol, BContainer, BSpinner, BCardBody, BImg,
} from 'bootstrap-vue'
import store from '@/store'
import BGImageButton from '@/components/BGImageButton.vue'
import Chat from '@/views/Chat.vue'
import ChatUnsubscribed from '@/views/ChatUnsubscirbed.vue'
import Subscription from '@/components/Subscription.vue'
import CardSpinner from '@/components/CardSpinner.vue'
import MAvatar from '@/components/MAvatar.vue'
import QuoteCard from '@/components/QuoteCard.vue'

export default {
  name: 'Home',
  components: {
    QuoteCard,
    MAvatar,
    CardSpinner,
    Subscription,
    Chat,
    ChatUnsubscribed,
    BGImageButton,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardTitle,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BContainer,
    BSpinner,
    BImg,
  },
  data() {
    return {
      userPricingPlanIndex: null,
      pricingPlans: [],
      quoteIndex: Math.floor(Math.random() * (24 + 1)),
    }
  },
  computed: {
    quote() {
      if (this.quoteIndex < 25) {
        return this.$t(`quotes.quote${this.quoteIndex}`)
      }

      return ''
    },
    quoteAuthor() {
      if (this.quoteIndex < 25) {
        return this.$t(`quotes.quoteAuthor${this.quoteIndex}`)
      }

      return ''
    },
    dashboardInfo() {
      return store.getters['app/dashboard']
    },
    validSubscription() {
      return this.dashboardInfo != null ? this.dashboardInfo.validSubscription : false
    },
    counselor() {
      return this.dashboardInfo != null && this.dashboardInfo.counselor !== undefined ? this.dashboardInfo.counselor : null
    },
    therapist() {
      return this.dashboardInfo != null && this.dashboardInfo.therapist !== undefined ? this.dashboardInfo.therapist : null
    },
    showCounselor() {
      return this.dashboardInfo != null && this.dashboardInfo.pairCounselor
    },
    showCounselorCard() {
      return !this.showPairingCard && this.showCounselor && this.counselor !== null
    },
    showAddCounselor() {
      return !this.showPairingCard && this.showCounselor && this.counselor == null
    },
    showTherapistCard() {
      return !this.showPairingCard && this.showTherapist && this.therapist !== null
    },
    showAddTherapist() {
      return !this.showPairingCard && this.showTherapist && this.therapist == null
    },
    showTherapist() {
      return this.dashboardInfo != null && this.dashboardInfo.pairTherapist
    },
    showPairingCard() {
      return (this.counselor == null && this.therapist == null)
    },
    userSubscription() {
      if (this.pricingPlans == null
        || this.pricingPlans.length === 0
        || this.userPricingPlanIndex == null
        || this.pricingPlans.length <= this.userPricingPlanIndex) {
        return null
      }

      return this.pricingPlans[this.userPricingPlanIndex]
    },
    brushStrokeImage() {
      return '@/assets/images/backgrounds/stroke.svg'
    },
    tipiActivityIndex() {
      return store.getters['app/activities'].findIndex(act => act.activityType === 'assessment' && act.name === 'tipi')
    },
    hasCompletedTipi() {
      return this.tipiActivityIndex == null || this.tipiActivityIndex < 0
    },
  },
  watch: {
    dashboardInfo(newVal) {
      if (newVal != null
        && newVal.validSubscription
        && newVal.subscriptionLevel != null
        && newVal.subscriptionLevel.length === 4
        && newVal.subscriptionLevel !== 'None') {
        const level = newVal.subscriptionLevel
        if (level.startsWith('D2C')) {
          const pricingPlanIndexPart = level.substring(3)
          const pricingPlanIndex = Number(pricingPlanIndexPart)
          if (!Number.isNaN(pricingPlanIndex)) {
            this.userPricingPlanIndex = pricingPlanIndex - 1
          }
        }
      }
    },
  },
  mounted() {
    store.dispatch('app/fetchDashboard')

    this.fetchPricingPlans()
  },
  methods: {
    openChat() {
      window.Intercom('show')
    },
    getStarted() {
      // we need to try to locate wizard for this and load it...
      const activities = store.getters['app/activities']
      const wizardIndex = activities.findIndex(act => act.name === 'OnboardingGroup' && act.journeyId != null)

      if (wizardIndex >= 0) {
        this.$router.push({
          name: 'wizard',
          params: {
            wizardId: activities[wizardIndex].id,
          },
        })
      }
    },
    pairWithCounselor() {
      // // we need to try to locate wizard for this and load it...
      // const activities = store.getters['app/activities']
      // const wizardIndex = activities.findIndex(act => {
      //   if (act.activities != null && act.activities.length > 0) {
      //     const carePairIndex = act.activities.findIndex(activity => activity.activityType === 'care-pair')
      //     if (carePairIndex >= 0) {
      //       return true
      //     }
      //   }
      //   return false
      // })
      //
      // if (wizardIndex >= 0) {
      //   this.$router.push({
      //     name: 'wizard',
      //     params: {
      //       wizardId: activities[wizardIndex].id,
      //     },
      //   })
      // }
      this.$router.push({
        name: 'care-pairing',
      })
    },
    pairWithTherapist() {
      this.$router.push({
        name: 'care-pairing-therapist',
      })
    },
    fetchPricingPlans() {
      this.isLoading = true

      this.$http.get('/subscriptions')
        .then(response => {
          this.pricingPlans = response.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    schedule() {
      // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({
        url: `${this.counselor.calendlyLink}`, // ?name=${fullName}&email=${email}
        prefill: {},
        utm: {},
      })
    },
    scheduleTherapist() {
      // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({
        url: `${this.therapist.calendlyLink}`, // ?name=${fullName}&email=${email}
        prefill: {},
        utm: {},
      })
    },
    takeTipi() {
      if (this.hasCompletedTipi) {
        return
      }

      const assessmentId = store.getters['app/activities'][this.tipiActivityIndex].activityMetaData
      this.$router.push({
        name: 'assessment',
        params: {
          assessmentId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables/_variables.scss";
@import './src/@core/scss/base/bootstrap-extended/include';

@media (min-width: (map-get($grid-breakpoints, lg))) {
  .border-right-lg {
    border-right: 1px solid $border;
  }
}

@media (min-width: (map-get($grid-breakpoints, md))) {
  .border-right-md {
    border-right: 1px solid $border;
  }
}

@media (min-width: (map-get($grid-breakpoints, sm))) {
  .border-right-sm {
    border-right: 1px solid $border;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .w-sm-fit-content {
    width: fit-content;
  }

  }

  .top-banner {
  width: 100%;
  padding: 1.5rem;
}
  .bg-blue{
     background-color: #C1D3DF;
  }
  .bg-orange{
    background-color: #FAD0BE;
  }
</style>
