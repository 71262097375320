<template>
  <b-card style="background-color: #DBEDDD;">
    <h6 class="font-weight-bold">
      | {{ $t('quotes.ofTheDayTitle').toUpperCase() }}
    </h6>
    <h2 class="my-2 font-weight-bolder">
      {{ quote.toUpperCase() }}
    </h2>
    <div class="w-100 d-inline-flex justify-content-between">
      <h6 class="font-weight-bold">
        | <i>{{ quoteAuthor }}</i>
      </h6>
      <b-img src="@/assets/images/icons/quote.svg" />
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
} from 'bootstrap-vue'

export default {
  name: 'QuoteCard',
  components: {
    BCard,
    BImg,
  },
  props: {
    quote: {
      type: String,
      required: true,
    },
    quoteAuthor: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
