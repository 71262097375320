<template>
  <div
    class="p-4"
    :style="{backgroundImage: `url(${bgImage})`, backgroundRepeat: 'no-repeat'}"
  >
    <b-button
      variant="primary"
      @click="$emit('click')"
    >
      {{ title }}
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import VueI18n from 'vue-i18n'

export default {
  name: 'BGImageButton',
  components: {
    BButton,
  },
  props: {
    title: {
      type: VueI18n.TranslateResult,
      required: true,
    },
    bgImage: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
