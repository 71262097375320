<template>
  <div />
</template>

<script>
export default {
  name: 'Chat',
  mounted() {
    this.$http.get('/user/profile')
      .then(response => {
        const user = response.data
        window.Intercom('boot', {
          app_id: 'xz2hji0x',
          vertical_padding: 50,
          email: user.email,
          name: user.alias,
        })
      })
  },
  destroyed() {
    window.Intercom('hide')
  },
}
</script>

<style scoped>

</style>
